import { Link } from "react-router-dom";
import "./main-education.css";

const MainEducation = () => {
  const MainEducationItem1 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-1"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-1"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">
          Understanding payday loans.
        </h2>
        <div className="main-education-item-text">
          Payday loans should help lower financial stress, not add to it. Before
          applying for a cash advance, ensure you’re dealing with a licensed and
          reputable lender. Visit the{" "}
          <a
            href="http://canadiancfa.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Canadian Consumer Finance Association
          </a>{" "}
          website to learn more about the industry, find a trusted lender, and
          learn about consumer protection in your province.
        </div>
      </div>
    </section>
  );

  const MainEducationItem2 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-2"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-2"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Budgeting.</h2>
        <div className="main-education-item-text">
          Whether you’re paying off debt, saving for a house, or planning for
          retirement, budgeting is key to achieving your financial goals. The
          Financial Consumer Agency of Canada has created a{" "}
          <a
            href="http://www.fcac-acfc.gc.ca/Eng/resources/toolsCalculators/Pages/home-accueil.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            budget worksheet
          </a>{" "}
          that you can download and revisit as your financial situation changes.
        </div>
      </div>
    </section>
  );

  const MainEducationItem3 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-3"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-3"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Calculators.</h2>
        <div className="main-education-item-text">
          <a
            href="http://www.creditcounsellingcanada.ca/index.php?page=tools-and-tips-for-money-management"
            target="_blank"
            rel="noopener noreferrer"
          >
            Credit Counselling Canada
          </a>{" "}
          gives you access to some powerful online tools, including a savings
          goal calculator, an emergency savings calculator, and more.
        </div>
      </div>
    </section>
  );

  const MainEducationItem4 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-4"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-4"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Credit counselling.</h2>
        <div className="main-education-item-text">
          <a
            href="http://www.creditcounsellingcanada.ca/index.php?page=tools-and-tips-for-money-management"
            target="_blank"
            rel="noopener noreferrer"
          >
            Credit Counselling Canada
          </a>{" "}
          provides confidential counseling by phone or in-person. There’s also
          the{" "}
          <a
            href="http://www.nomoredebts.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Credit Counselling Society
          </a>
          , which offers free, live support online or by phone.
        </div>
      </div>
    </section>
  );

  const MainEducationItem5 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-5"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-5"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Workshops.</h2>
        <div className="main-education-item-text">
          The Credit Counselling Society hosts{" "}
          <a
            href="http://www.nomoredebts.org/credit-counselling-society/consumer-services/credit-budgeting-workshops/schedule.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            free webinars and in-person workshops
          </a>{" "}
          on a variety of topics. Learn how to make healthier spending choices,
          manage your finances as a couple, and more.
        </div>
      </div>
    </section>
  );

  const MainEducationItem6 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-6"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-6"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Practical savings advice.</h2>
        <div className="main-education-item-text">
          From lowering your grocery bill to teaching kids the difference
          between needs and wants,{" "}
          <a
            href="http://www.moneysense.ca/saving/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MoneySense.ca
          </a>{" "}
          shares valuable savings advice courtesy of Canadian financial expert
          Gail Vaz-Oxlade.
        </div>
      </div>
    </section>
  );

  const MainEducationItem7 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-7"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-7"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Credit reports.</h2>
        <div className="main-education-item-text">
          Want to know how you look to prospective lenders? For a fee,{" "}
          <a
            href="http://www.consumer.equifax.ca/home/en_ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            Equifax
          </a>{" "}
          and{" "}
          <a
            href="http://www.transunion.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            TransUnion
          </a>{" "}
          will provide you with your credit score. For more information on how
          to improve your credit score,{" "}
          <a
            href="https://www.canada.ca/en/financial-consumer-agency/services/credit-reports-score.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            review this guide
          </a>{" "}
          from the Financial Consumer Agency of Canada.
        </div>
      </div>
    </section>
  );

  const MainEducationItem8 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-8"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-8"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Debt collection.</h2>
        <div className="main-education-item-text">
          If debt collectors are after you, don’t bury your head in the sand.
          Empower yourself to handle the situation responsibly. The Financial
          Consumer Agency of Canada offers valuable{" "}
          <a
            href="http://www.fcac-acfc.gc.ca/Eng/forConsumers/topics/yourRights/Pages/DebtColl-Recouvre.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            tips on dealing with debt collectors
          </a>
          , including insights into your legal rights.
        </div>
      </div>
    </section>
  );

  const MainEducationItem9 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-9"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-9"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">
          Finance Apps &amp; Deal Sites.
        </h2>
        <div className="main-education-item-text">
          We’ve created a{" "}
          <a
            href="https://goday.ca/blog/deal-websites-download/"
            target="_blank"
            rel="noopener noreferrer"
          >
            FREE downloadable PDF
          </a>{" "}
          of the best free (or close to free) finance apps &amp; money-saving
          deal websites just for you.
        </div>
      </div>
    </section>
  );

  const MainEducationItem10 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-10"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-10"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Glossary.</h2>
        <div className="main-education-item-text">
          Got your personal finance terms in a twist? Check out this{" "}
          <a
            href="http://www.fiscalagents.com/newsletter/gloss/Glossary/creditgloss.shtml"
            target="_blank"
            rel="noopener noreferrer"
          >
            financial glossary
          </a>{" "}
          courtesy of Fiscal Agents.
        </div>
      </div>
    </section>
  );

  const MainEducationItem11 = () => (
    <section
      className="main-education-item content clearfix"
      id="main-education-item-11"
    >
      <div
        className="main-education-item-icon gallery"
        id="main-education-item-icon-11"
      >
        <span></span>
      </div>
      <div className="main-education-item-group">
        <h2 className="main-education-item-title">Cost of a Payday Loan.</h2>
        <div className="main-education-item-text">
          As a responsible borrower it is important to know the costs of
          borrowing a payday loan as compared to other borrowing products. 
          We have exact fees disclosed on your application page, 
          as well as on your actual loan agreement.
          Are you from British Columbia? Check out these{" "}
          <a
            href="http://www.consumerprotectionbc.ca/consumers-payday-lending-home/consumer-tips/1148-tips-for-online-payday-lending"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Tips for Online Payday Lending
          </a>{" "}
          from Consumer Protection BC.
        </div>
      </div>
    </section>
  );

  return (
    <main className="main-education">
      <h1 className="main-education-title content">
        Understanding payday loans, short term loans, and personal finance
        options
      </h1>
      <div className="main-education-text content">
        <p>
          In today’s economy, it’s hardly surprising that Canadians find
          themselves turning to GoDay for{" "}
          <Link to="/why-us">short-term loans</Link> from time to time. In an
          ideal scenario, we’d all have sufficient savings to draw from when we
          hit a financial hurdle, but sometimes you need access to{" "}
          <Link to="/">online payday loans</Link> to make ends meet. We make
          this process as simple, easy, and secure as possible.
        </p>
        <p>
          <Link to="/">GoDay</Link> is a payday loan provider that offers{" "}
          <Link to="/how-it-works">short-term solution to cash flow</Link>{" "}
          shortages. For the long-term, we recommend you visit your financial
          institution and ask to speak with an advisor who can help you manage
          any existing debt, create a rainy day fund, plan for your retirement,
          and more. If surviving from paycheck to paycheck or managing your debt
          load is a serious struggle, you may also want to consider meeting with
          a credit counsellor.
        </p>
        <p>
          To support - not replace - the advice of a professional, we’ve
          compiled some helpful resources below. Come back often as this page is
          always evolving to serve you better.
        </p>
      </div>
      <MainEducationItem1 />
      <MainEducationItem2 />
      <MainEducationItem3 />
      <MainEducationItem4 />
      <MainEducationItem5 />
      <MainEducationItem6 />
      <MainEducationItem7 />
      <MainEducationItem8 />
      <MainEducationItem9 />
      <MainEducationItem10 />
      <MainEducationItem11 />
    </main>
  );
};

export default MainEducation;
